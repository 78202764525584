@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// ---------------------------------------------------------------------------------------------------------------------
// Constants
// ---------------------------------------------------------------------------------------------------------------------
$col-base: md;

// ---------------------------------------------------------------------------------------------------------------------
// Variables
// ---------------------------------------------------------------------------------------------------------------------
.super-slice-section-division,
.super-slice-section {
  /* Spacing */
  --unit: 2rem; // 1rem = 10px
  --spaceIncrement: 1.5;

  // XS = S * 0.5 = 15px
  // S = 30px
  // M = S * 1.5 = 45px

  /* Spacing */
  --space-s: var(--unit);
  --space-m: calc(var(--space-s) * var(--spaceIncrement));
  --space-l: calc(var(--space-m) * var(--spaceIncrement));
  --space-xl: calc(var(--space-l) * var(--spaceIncrement));
  --space-2xl: calc(var(--space-xl) * var(--spaceIncrement));
  --space-3xl: calc(var(--space-2xl) * var(--spaceIncrement));
  --space-4xl: calc(var(--space-3xl) * var(--spaceIncrement));
}

.super-slice-section {
  /* Typography */
  --baseTextSize: 1.4rem; // 1rem = 10px;
  --textSizeIncrement: 1.5;

  /* Typography */
  --text-xs: calc(var(--baseTextSize) / var(--textSizeIncrement));
  --text-s: var(--baseTextSize);
  --text-m: calc(var(--text-s) * var(--textSizeIncrement));
  --text-l: calc(var(--text-m) * var(--textSizeIncrement));
  --text-xl: calc(var(--text-l) * var(--textSizeIncrement));
}

// ---------------------------------------------------------------------------------------------------------------------
// Super slice main layout
// ---------------------------------------------------------------------------------------------------------------------
.super-slice-section {
  overflow: hidden;
  position: relative;
  font-size: var(--text-s);

  /* reset height on baseColVar */
  @include media-breakpoint-down($col-base) {
    height: auto!important;
  }

  & > div {
    height: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__background-media {
    position: absolute;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    width: 100%;
    left: 0;

    & > * {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    width: 100%;
    padding: var(--space-l);
    font-size: inherit;

    &.super-slice-section__content--no-padding {
      padding: 0;
    }
  }

  h1,h2,h3,h4,h5,h6 {
    color: inherit;
    font-weight: 400;
    line-height: 1.3;
  }

  h1 {
    font-size: var(--text-xl);
    margin-block-start: 0.4rem;
    margin-block-end: 0.4rem;
  }

  h2 {
    font-size: var(--text-l);
    margin-block-start: 0.7rem;
    margin-block-end: 0.7rem;
  }

  h3 {
    font-size: var(--text-m);
    margin-block-start: 0.8rem;
    margin-block-end: 0.8rem;
  }

  h4 {
    margin-block-start: 1.2rem;
    margin-block-end: 0.4rem;
  }

  h4,h5,h6 {
    font-size: var(--text-s);
  }

  p {
    font-size: var(--text-s);
    color: inherit;

    a {
      color: inherit;
      text-decoration: underline;

      &:hover {
        opacity: 0.5;
      }
    }

  }

  ul {
    list-style: unset;
    padding-left: var(--space-m);
  }

  .block-img img {
    max-width: 100%;
    height: auto;
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// Background media for main container
// ---------------------------------------------------------------------------------------------------------------------
.container-background-media {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

// ---------------------------------------------------------------------------------------------------------------------
// Super slice media
// ---------------------------------------------------------------------------------------------------------------------

/* The media container in a super slice media */
.slice-media {
  position: relative;

  &.slice-media--image {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  &.slice-media--video video {
    display: block;
    object-fit: cover;
    max-width: 100%;
    height: auto;
  }

  &__text {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--text-m);
    pointer-events: none;
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// Super slice button
// ---------------------------------------------------------------------------------------------------------------------
.super-slice-button {
  position: relative;
  margin-top: 20px;
  text-align: center;

  a {
    position: relative;
    z-index: 1;
    background: #fff;
    text-transform: uppercase;
    border: solid 1px #000;
    padding: 20px 30px;
    color: #000;
    display: inline-block;
    font-size: 1.2rem;

    &:hover {
      color: #000;
    }
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// Division
// ---------------------------------------------------------------------------------------------------------------------
.super-slice-section-division {
  &.super-slice-section-division--space-s {
    height: var(--space-s);
  }
  &.super-slice-section-division--space-m {
    height: var(--space-m);
  }
  &.super-slice-section-division--space-l {
    height: var(--space-l);
  }
  &.super-slice-section-division--space-xl {
    height: var(--space-xl);
  }
  &.super-slice-section-division--space-2xl {
    height: var(--space-2xl);
  }
  &.super-slice-section-division--space-3xl {
    height: var(--space-3xl);
  }
  &.super-slice-section-division--space-4xl {
    height: var(--space-4xl);
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// Utilities
// ---------------------------------------------------------------------------------------------------------------------
.container-fluid {
  &.container-fluid--fw {
    padding-left: 0;
    padding-right: 0;
  }
}

.container-super {
  position: relative;
  overflow: hidden;

  & > .row {
    position: relative;
    z-index: 2;
  }
}
